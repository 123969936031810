<template>
  <v-list-group v-if="chat.contact">
    <template v-slot:activator>
      <v-list-item-avatar>
          <avatar :username="chat.contact.name"></avatar>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          {{ chat.contact.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
            <p>
                Ultima attività {{new Date(chat.last_activity).toLocaleString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}}
            </p>
          <p>
            ID Contatto: ctn-{{ chat.contact.id }}
          </p>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-for="(serviceChat, index) in servicesChats">
      <v-list-item v-if="emptyService(serviceChat)" :key="index">
        <v-list-item-content>
          <v-list-item-subtitle> 
            Ancora nessuna chat per questo contatto
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else :key="index" @click="$emit('switchChat', serviceChat.source, index)">
        <v-list-item-avatar >
          <v-icon color="white">
            {{serviceChat.source | chatServiceIcon}}
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ serviceChat.source | chatService }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <p>
              Ultimo messaggio {{new Date(serviceChat.lastActivity).toLocaleString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }) }}
            </p>
            <p>
              ID Chat: chat-{{ chat.id }}
            </p>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list-group>
</template>

<script>
  import Avatar from 'vue-avatar';
  import LogoSignal from '@/assets/Signal-Logo.svg';
  import axios from '../../axios';
  import {apiUrl} from "@/common/endpoint.const";
  export default {
    name: "ChatContactItem",
        props: ['chat', 'filterData', 'filterContact', 'relevanceGrade', 'idMessageSearch'],
    data(){
      return {
        LogoSignal:LogoSignal,
        servicesChats:[]
      }
    },
    components: {
      Avatar
        },
    methods: {
      async getMessageFromChatId(){
        console.log("chat: ",this.chat.id);
      },
      emptyService(service){
        console.log("service: ",service, !service && Object.entries(service).length === 0);
        return !service && Object.entries(service).length === 0
      },
      async getChatByService(){
        //console.log(this.chat.messages)
        //console.log(this.lodash.groupBy([{service:1,},{service:2,},{service:2}], message => message.service+''))
        console.log("chat: ",this.chat);
        //return this.lodash.groupBy(this.chat.messages, message => message.source + '');
          const result = await axios.post(`${apiUrl}/device/chat-by-service`,
              {
                chatId: this.chat.id,
                filterData: this.filterData,
                filterContact: this.filterContact,
                relevanceGrade: this.relevanceGrade,
                idMessageSearch: this.idMessageSearch
              }
          );
        console.log("result: ",result);
        
        if(result.status === 200){
          this.servicesChats = result.data.map(chat => {
              return {
                  source: chat.source,
                  lastActivity: chat.lastActivity
              };
          });
        }
      },
      lastMessage(serviceChat){
        console.log("serviceChat: ",serviceChat);
        return serviceChat[serviceChat.length - 1];
      }
    },
    async mounted() {
      await this.getChatByService();
      await this.getMessageFromChatId();
    }
  }
</script>

<style scoped>

</style>