export const LOGIN = 'login';
export const LOGOUT = "logout";
export const WS_AUTH = 'wsAuth';
export const RESTORE_AUTH = 'restoreAuth';
export const AUTHENTICATE = 'authenticate';
export const RESET_SESSION = 'resetSession';

export const FETCH_ALL_DEVICES = 'fetchAllDevices';
export const FETCH_DEVICE = 'fetchDevice';
export const FETCH_DEVICE_CALL_REGISTER = 'fetchDeviceCallRegister';
export const FETCH_DEVICE_CONTACT_REGISTER = 'fetchDeviceContactRegister';
export const FETCH_DEVICE_CHATS = 'fetchDeviceChats';
export const FETCH_DEVICE_RELEVANCE = 'fetchDeviceRelevance';
export const NOTIFY_OBSERVERS = 'notifyObservers';
export const FETCH_DEVICE_FILESYSTEM = 'fetchDeviceFilesystem';
export const FETCH_DEVICE_SENSORS = 'fetchDeviceSensors';
export const FETCH_DEVICE_POSITIONS = 'fetchDevicePositions';
export const FETCH_DEVICE_KEYLOG = 'fetchDeviceKeylog';
export const DEVICE_CONTACT_LIST_SORT = 'deviceContactListSort'
export const DEVICE_START_FETCH = 'deviceStartFetch';
export const DEVICE_STOP_FETCH = 'deviceStopFetch';
export const DEVICE_INIT = 'deviceInit';
export const SUBSCRIBE_ON_DEVICE_ROOM = 'subscribeOnDeviceRoom';
export const UNSUBSCRIBE_ON_DEVICE_ROOM = 'unsubscribeOnDeviceRoom';
export const ADD_OBSERVER = 'addObserver';
export const REMOVE_OBSERVER = 'removeObserver';
export const DEVICE_STATE_UPDATE = 'deviceStateUpdate';
export const FETCH_DEVICE_PERMISSIONS  = 'devicePermissions';
export const PAGE_LOG_UPDATE  = 'pageLogUpdate';


export const SEND_COMMAND = 'sendCommand';
export const SERVER_COMMAND_ACK = 'serverCommandAck';

export const NOTIFICATION_FROM_SERVER = 'notificationFromServer';

export const SOCKET_CONNECT = 'socketConnect';
export const SOCKET_SEND = 'socketSend';
export const SOCKET_AUTH = 'socketAuth';
export const SOCKET_AUTH_RESPONSE = 'socketAuthResponse';
export const SOCKET_ONOPEN = 'SOCKET_ONOPEN';
export const SOCKET_ONCLOSE = 'SOCKET_ONCLOSE';
export const SOCKET_ONERROR = 'SOCKET_ONERROR';
export const SOCKET_ONRECONNECT = 'SOCKET_RECONNECT';
export const SOCKET_ONMESSAGE = 'SOCKET_ONMESSAGE';
export const EMPTY_SOCKET_BUFFER = 'emptySocketBuffer';

export const DOWNLOAD_FILE = 'downloadFile';
export const SERVER_FILE_UPLOADED = 'serverFileUploaded';
export const REFRESH_DIRECTORY = 'refreshDirectory';
export const REFRESH_DIRECTORY_COMMAND = 'refreshDirectoryCommand';

export const CALL_REGISTER_EVENT = 'callRegisterEvent';

export const CONTACT_ADDED = 'contactAdded';

export const ADDED_RELEVANCE = 'addedRelevance';
export const NOTIFICATION_UPDATE = 'notificationUpdate';

export const NEW_CHAT_MESSAGE = 'newChatMessage';