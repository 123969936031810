<template>
    <v-container class="pa-0">

        <v-toolbar>
            <strong><em>Dashboard Ulisse</em></strong>
            <v-spacer></v-spacer>
            <span v-if="info?.rgnr != null">
                <strong> RGNR: </strong><em>{{ info?.rgnr }}</em>&nbsp;&nbsp;&nbsp;
            </span>
            <span v-if="info?.ritCode != null">
                <strong> RIT: </strong><em>{{ info?.ritCode }}</em>&nbsp;
            </span>
            <span v-if="info?.ritAmbientCode != null">
                <em>- {{ info?.ritAmbientCode }}</em>&nbsp;&nbsp;&nbsp;
            </span>
            <span v-if="expirationDate != null">
                <strong>Scadenza: </strong> {{ new Date(expirationDate).toLocaleString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' }) }} ({{ remainingDays() }})
            </span>
        </v-toolbar>

        <v-row class="d-flex align-center justify-center">
            <v-col cols="5" align-self="start">
                <device-info class="mt-4" :device="device" />
                <device-console :device="device" :user="user" />
                <device-permission :device="device" v-if="getUsername == 'admin'" />
            </v-col>
            <v-col cols="5" align-self="start">
                <v-card class="mt-4">
                    <v-card-title>Stato della batteria</v-card-title>
                    <v-card-text>
                        <v-sparkline :value="batteryValues"
                                     :smooth="12"
                                     :gradient="['#f72047', '#ffd200', '#1feaea'].reverse()"
                                     :line-width="2"
                                     :fill="false"
                                     auto-draw></v-sparkline>
                        <v-list three-line>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Consumo massimo raccomandato</v-list-item-title>
                                    <v-list-item-subtitle>Superato questo limite il telefono potrebbe notificare all'utente che un'applicazione sta effettuando operazioni in background, creando un campanello d'allarme</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    6%/h
                                </v-list-item-action>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Consumo attuale</v-list-item-title>
                                    <v-list-item-subtitle>Questo dato indica la percentuale di batteria consumata nell'arco dell'ultima ora</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    3%/h
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>

                <v-card class="mt-4">
                    <v-card-title>Consumo dati</v-card-title>
                    <v-card-text>
                        <v-sparkline :value="trafficValues"
                                     :smooth="12"
                                     :gradient="['#f72047', '#ffd200', '#1feaea']"
                                     :line-width="2"
                                     :fill="false"
                                     auto-draw></v-sparkline>
                        <v-divider></v-divider>
                        <v-list three-line>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Consumo massimo raccomandato</v-list-item-title>
                                    <v-list-item-subtitle>Superato questo limite il telefono potrebbe notificare all'utente che un'applicazione sta effettuando operazioni in background, creando un campanello d'allarme</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    50MB
                                </v-list-item-action>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Consumo attuale</v-list-item-title>
                                    <v-list-item-subtitle>Questo dato indica i dati inviati e ricevuti dal telefono nell'arco della giornata</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    {{trafficValues[trafficValues.length-1]*1024 | fileSize}}
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>

                <v-card class="mt-4">
                    <device-conf v-if="getUsername == 'admin'" />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
	import DeviceInfo from "@/components/device/DeviceInfo";
	import DeviceConf from "@/components/device/DeviceConf";
	import Device from "@/store/models/device.model";
	import { DEVICE_INIT, FETCH_DEVICE } from "@/store/actions.type";
	import DeviceConsole from "@/components/device/DeviceConsole";
	import DevicePermission from "@/components/device/DevicePermission";
    import { mapGetters } from "vuex";
    import axios from '../../axios';
    import { apiUrl } from "@/common/endpoint.const";

	export default {
		name: "Dashboard",
		components: {
			DeviceInfo,
			DeviceConsole,
			DevicePermission,
			DeviceConf
        },
        data() {
            return {
                info: null,
                expirationDate: null
            }
        },
        methods: {
            goTo(path) {
                this.$router.push(path);
            },
            logout() {
                this.$store.dispatch('logout').then(() => this.$router.push('/login'))
            },
            async fetchDeviceInfo() {
                var result = await axios.get(`${apiUrl}/device/get-device-info-with-id`)
                    .then((result) => {
                        console.log("GetDeviceInfoDashboard: ", result);
                        if (result.status == 200) {
                            this.info = result.data[0];
                            this.expirationDate = this.info.ritAmbientDate != null ? this.info.ritAmbientDate : this.info.ritDate;
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
            },
            remainingDays() {
                var today = new Date();
                var expiration = new Date(this.expirationDate.replace(" ", "T"));
                var diffInMilliseconds = expiration - today;
                var diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
                if (diffInDays > 1) {
                    return diffInDays + " giorni rimanenti";
                }
                else if (diffInDays === 1) {
                    return diffInDays + " giorno rimanente";
                }
                else {
                    return "Scaduto";
                }
            }
        },
        computed: {
            ...mapGetters(['isAuthenticated']),
            device() {
                return Device.query().with('state').with('batteryRegister').with('trafficRegister').find(sessionStorage.getItem("deviceId"))
            },
            getUsername() {
                return this.$store.getters.currentUser.username
            },
            user() {
                return this.$store.getters.currentUser
            },
            isFetching() {
                return Device.getters('isFetching')
            },
            batteryValues() {
                let vals = this.device.batteryRegister.map(x => x.level)
                return [100, 0].concat(vals);
            },
            trafficValues() {
                let sum = 0;
                let vals = this.device.trafficRegister.map(x => {
                    sum += x.value
                    return sum;
                })
                return [50000, 0].concat(vals)
            }
        },
        created() {
            Device.dispatch(DEVICE_INIT, { id: sessionStorage.getItem("deviceId") })
            Device.dispatch(FETCH_DEVICE, { id: sessionStorage.getItem("deviceId") })
            const appvue = this.$root.$children[0];
            appvue.getNumberOfNotReadNotification();
            this.fetchDeviceInfo();
        }
    }
</script>

<style scoped>
</style>