import { render, staticRenderFns } from "./ChatContactItem.vue?vue&type=template&id=377104c5&scoped=true"
import script from "./ChatContactItem.vue?vue&type=script&lang=js"
export * from "./ChatContactItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "377104c5",
  null
  
)

export default component.exports