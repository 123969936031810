import JwtService from '../common/jwt.service';
import axios from '../axios';
import { RESET_SESSION, LOGIN, LOGOUT, RESTORE_AUTH, SOCKET_AUTH, SOCKET_SEND, WS_AUTH, AUTHENTICATE } from "@/store/actions.type";
import { PURGE_AUTH, SET_AUTH, SET_ERROR, SET_AUTHENTICATE } from "@/store/mutations.type";
import { apiUrl } from "@/common/endpoint.const";
import User from "@/store/models/user.model";
import Swal from 'sweetalert2';

const state = {
	errors: null,
	user: JSON.parse(sessionStorage.getItem('user')) || {},
	token: '',
	isAuthenticated: !!JwtService.getToken()
};

const getters = {
	currentUser(state) {
		return state.user;
	},
	isAuthenticated(state) {
		return state.isAuthenticated;
	}
};

const actions = {
	async [LOGIN](context, credentials) {
		await axios({
			method: 'POST',
			url: `${apiUrl}/auth/login`,
			data: credentials,
		}).then(({ data }) => {
			console.log("data", data);
			context.commit(SET_AUTH, data)
			console.log(`${context.state.user.username} logged in! dispatching WS_AUTH`)
			context.dispatch(WS_AUTH, context.state.user.secret);
		}).catch(({ response }) => {
			context.commit(SET_ERROR, response.data)
			console.log("err", response);
			if (response.data.title === "Unauthorized") {
				Swal.fire({
					icon: 'error',
					title: 'Errore ' + response.data.status,
					text: 'Username o password errati',
				});
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: response.data.detail,
				});
			}
			throw new Error(response.data.detail);
		})
	},
	[RESTORE_AUTH](context) {
		if (!!JwtService.getToken() && state.user && state.user.secret) {
			context.commit(SET_AUTH, {
				user: state.user,
				access_token: JwtService.getToken()
			})
			context.dispatch(WS_AUTH, context.state.user.secret);
		}
	},
	[LOGOUT](context) {
		context.commit(PURGE_AUTH);
	},
	[AUTHENTICATE](context) {
		context.commit(SET_AUTHENTICATE);
	},
	async [WS_AUTH](context, secret) {
		if (!secret && !context.state.token) {
			console.error('WS_AUTH: no secret provided for authentication')
			return false;
		}
		console.log(context.state.token)
		//TODO gestire la mancata connessione
		console.log('authenticating ws...')
		return await context.dispatch(
			SOCKET_AUTH,
			secret || context.state.token,
			{ root: true }
		)
	},
	async [RESET_SESSION](context) {
		sessionStorage.removeItem('user')
		JwtService.destroyToken();
		sessionStorage.removeItem('deviceId')
		sessionStorage.removeItem('lastPage')
	}
}

const mutations = {
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_AUTHENTICATE](state) {
		state.isAuthenticated = true;
	},
	[SET_AUTH](state, { user, access_token }) {
		state.user = user;
		state.errors = {};
		state.token = user.secret;
		User.create({
			data: user
		})
		sessionStorage.setItem('user', JSON.stringify(user))
		JwtService.saveToken(access_token);
		//  axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
		//  axios.defaults.headers.common['athena-deviceid'] = sessionStorage.getItem('deviceId');
	},
	[PURGE_AUTH](state) {
		state.isAuthenticated = false;
		state.user = {};
		state.errors = {};
		state.token = '';
	}
}

export default {
	state,
	actions,
	mutations,
	getters
};