<template>
    <v-card class="mx-auto"
            v-if="device">
        <v-list-item five-line>
            <v-list-item-content>
                <v-list-item-title class="headline">
                    {{ device.manufacturer }} {{ device.model }}
                </v-list-item-title>

                <v-list-item-subtitle v-if="device.imei1 != ''"> IMEI 1: {{ device.imei1 }} </v-list-item-subtitle>
                <v-list-item-subtitle v-if="device.imei2 != ''"> IMEI 2: {{ device.imei2 }} </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>

        <!--<v-card-text>
        -<v-img
            src="@/assets/huawei.jpeg"
            alt="Device Image"
            width="92"
        ></v-img>
    </v-card-text>-->

        <v-list subheader class="transparent">
            <v-subheader>
                Informazioni
            </v-subheader>

            <v-list-item>
                <v-list-item-title>
                    Sistema Operativo
                </v-list-item-title>

                <v-list-item-icon>
                    <img v-if="device.os == 'Android'" src="../../../assets/icons/deviceOS/android-white.png" alt="Logo Dispositivo" width="25" />
                    <img v-else-if="device.os == 'iOS'" src="../../../assets/icons/deviceOS/apple-white.png" alt="Device Logo" width="25" />
                    <img v-else src="../../../assets/icons/deviceOS/smartphone-white.png" alt="Device Logo" width="25" />
                </v-list-item-icon>

                <v-list-item-subtitle v-if="device.os != '' || device.so_version != ''" class="text-right"> {{ device.os }} {{ device.so_version }} </v-list-item-subtitle>
                <v-list-item-subtitle v-else class="text-right"> Sconosciuto </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
                <v-list-item-title>Memoria in uso</v-list-item-title>

                <v-list-item-icon>
                    <v-icon>mdi-sd</v-icon>
                </v-list-item-icon>

                <v-list-item-subtitle class="text-right">
                    {{ device.state.available_memory/1024 | number}} / {{device.max_memory / 1024 | number}} GB
                </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
                <v-list-item-title>Batteria</v-list-item-title>

                <v-list-item-icon>
                    <v-icon :color="device.state.battery | batteryLevelColor">mdi-battery</v-icon>
                </v-list-item-icon>

                <v-list-item-subtitle class="text-right">
                    {{ device.state.battery | number}} %
                    <span v-if="device.state.usb_is_plugged">(in carica)</span>
                    <span v-else>(non in carica)</span>
                </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
                <v-list-item-title>Wi-Fi</v-list-item-title>

                <v-list-item-icon>
                    <v-icon color="green" v-if="device.state.wifi_is_on">mdi-wifi-strength-4</v-icon>
                    <v-icon color="red" v-else>mdi-wifi-strength-off</v-icon>
                </v-list-item-icon>

                <v-list-item-subtitle class="text-right">
                    <span v-if="device.state.wifi_is_on">Connesso</span>
                    <span v-else>Disconnesso</span>
                </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
                <v-list-item-title>Microfono</v-list-item-title>

                <v-list-item-icon>
                    <v-icon color="green" v-if="device.state.mic_is_available">mdi-microphone</v-icon>
                    <v-icon color="red" v-else>mdi-microphone-off</v-icon>
                </v-list-item-icon>

                <v-list-item-subtitle class="text-right">
                    <span v-if="device.state.mic_is_available">In registrazione</span>
                    <span v-else>Inattivo</span>
                </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
                <v-list-item-title>Connesso</v-list-item-title>

                <v-list-item-icon>
                    <v-icon color="green" v-if="device.state.is_connected">mdi-lan-connect</v-icon>
                    <v-icon color="red" v-else>mdi-lan-disconnect</v-icon>
                </v-list-item-icon>

                <v-list-item-subtitle class="text-right">
                    <span v-if="device.state.is_connected">Connesso</span>
                    <span v-else>Disconnesso</span>
                </v-list-item-subtitle>
            </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <!--<v-card-actions>
        </v-card-actions>-->
    </v-card>
</template>

<script>
	import Console from "@/store/models/console.model";

	export default {
		name: "DeviceInfo",
		props: ['device'],
		data() {
			console.log("DEVICE: ", this.device);
			return {
				console: null,
				intervalId: null, // Aggiunto per mantenere traccia dell'ID del setInterval
			}
		},
		async created() {
			if (!this.console) {
				console.log('[CREATING CONSOLE...]')
				await Console.insertOrUpdate({
					data: {
						deviceId: sessionStorage.getItem("deviceId"),
						device: this.device,
					}
				}).then((data) => {
					this.console = data.console[0];
				});
			}
			await this.sendCommand();
			// Creare e avviare il setInterval
			// this.intervalId = setInterval(async () => {
			//   console.log('Sending ping command...')
			//   await this.sendCommand();
			// }, 10000);
		},
		// Aggiunto l'hook beforeDestroy per rimuovere il setInterval quando il componente viene distrutto
		beforeDestroy() {
			clearInterval(this.intervalId);
		},
		methods: {
			async sendCommand() {
				await this.console.sendCommand(
					{
						cmd: "PING",
						params: ["not-show-noty"],
						user: JSON.parse(sessionStorage.getItem('user')) || {}
					},
					sessionStorage.getItem("deviceId")
				);
			}
		}
	}
</script>

<style scoped>
</style>