<template>
  <div v-if="device">
    <v-row class="pa-4">
      <v-col cols="9" align-self="start">
        <v-card>
          <v-subheader>Elenco rilevanze acquisite</v-subheader>
          <v-card-title>
            <v-row dense>
              <v-col cols="3">
                <v-select 
                  v-model="relevanceGrade" 
                  :items="relevanceGrades" 
                  label="Rilevanza" 
                  return-object
                  item-text="label" 
                  item-value="value"
                ></v-select>

              </v-col>
              <v-col cols="3">
                <v-select 
                  v-model="relevanceNote" 
                  :items="relevanceNotes" 
                  label="Note" 
                  return-object 
                  item-text="label"
                  item-value="value"
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select v-model="listenChoice" :items="listenChoices" label="Gestita" return-object
                  item-text="label" item-value="value"></v-select>
              </v-col>
              <v-col cols="3">
                <v-select v-model="typeChoice" :items="typeChoices" label="Tipologia" return-object item-text="label"
                  item-value="value"></v-select>
              </v-col>
              <v-col cols="2">
                <v-menu :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field 
                      v-model="filterDateFrom" 
                      label="Dalla data" 
                      prepend-icon="mdi-calendar" 
                      readonly
                      v-on="on"
                    >
                    </v-text-field>
                    
                  </template>
                  <v-date-picker v-model="filterDateFrom" @input="menu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2">
                <v-menu :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="filterDateTo" label="Alla data" prepend-icon="mdi-calendar" readonly
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="filterDateTo" @input="menu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="1" style="display: flex;align-items: center;">
                <div class="tooltip">
                  <v-btn color="green" block @click="resetFilterData()">
                    <v-icon style="font-size: 20px;">mdi-delete</v-icon>
                  </v-btn>
                  <span class="tooltiptext">Resetta i filtri date</span>
                </div>
              </v-col>
              <v-col cols="2">
                <v-text-field v-model="filterTranscription" label="Trascrizione"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field v-model="filterSynthesis" label="Sintesi"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="filterProgNumber" type="number" label="Progressivo"></v-text-field>
              </v-col>
              
            </v-row>
            <v-row>
              <span class="simple-text">Ti rimangono da gestire {{numberOfNotListenerRelevance}} rilevanze</span>
            </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-list three-line dense>
            <v-list-item v-if="loading">
              <v-progress-circular indeterminate></v-progress-circular> Caricamento rilevanze in corso...
            </v-list-item>
            <v-list-item v-else-if="relevanceData.length === 0 && pageItems === 0">
              <v-list-item-content>
                <v-list-item-title>Nessuna rilevanza presente in elenco</v-list-item-title>
                <v-list-item-subtitle>Il telefono non ha ancora acquisito nessuna rilevanza</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div v-else v-for="(relevance,index) in relevanceData.length === 0 ? pageItems : relevanceData" :key="index">
              <div class="tooltip"  style="width:100%;">
                <v-list-item @click.stop="goToTranscription(relevance)">
                <v-checkbox
                    :label="''"
                    color="primary"
                    @click.stop
                    @change="selectSingleRelevanceForGrade(index,relevance.id)"
                    v-model="selectElement[page][index]"
                  ></v-checkbox>
                  <v-list-item-avatar>
                    <v-avatar :color="relevance.type | relevanceTypeIconColor">
                      <v-icon dark>{{ relevance.type | relevanceTypeIcon }}</v-icon>
                    </v-avatar>
                  </v-list-item-avatar>
  
                  <v-list-item-content>
                    <v-list-item-title>
                      
                      {{ relevance.type | relevanceTypeLabel }} del {{ ProxyAddOneHour(relevance.created_at,relevance.type) }}
                        <v-spacer style="display: inline-block"></v-spacer>
                        <v-icon style="display: inline-block" v-if="relevance.transcriptionId === null || relevance.transcriptionId.toString().includes('$uid')">mdi-check</v-icon>
                        <v-icon style="display: inline-block" v-if="relevance.transcriptionId !== null && !relevance.transcriptionId.toString().includes('$uid')">mdi-check-all</v-icon>
                        <br/>
                      <!-- {{relevance.type | relevanceTypeLabel}} del {{new Date(relevance.created_at).getTime()/ 1000}} -->
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span 
                        v-if="
                              (relevance.transcriptionId !== null && !relevance.transcriptionId.toString().includes('$uid')) && 
                              (relevance.transcription && relevance.transcription.user && relevance.transcription.user.username)"
                      >
                        - creata da: {{ relevance.transcription.user.username }}
                      </span>
                      
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <span v-if="(relevance.transcriptionId !== null && !relevance.transcriptionId.toString().includes('$uid'))">
                        - Trascrizione: {{ relevance.transcription.text.toString().slice(0,50) }}...
                      </span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <span v-if="(relevance.synthesisId !== null && !relevance.synthesisId.toString().includes('$uid'))">
                        - Sintesi: {{ relevance.synthesis.text.toString().slice(0,50) }}...
                      </span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <span>
                        - Esportato in Word: {{ relevance.is_exported ? "Si" : "No" }}
                      </span>
                    </v-list-item-subtitle>
  
                    <v-list-item-subtitle>
                      <span> Progressivo: {{ relevance.id }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="socketArray.includes(relevance.id) && !checkIfUserIsSame(relevance.id)">
                      <span style="color:red">
                        Traccia in ascolto da un altro operatore
                      </span>
                    </v-list-item-subtitle>
                    <!-- v-if="(relevance.transcriptionId !== null && relevance.relevance_grade != null && relevance.transcriptionId !== null && !relevance.transcriptionId.toString().includes('$uid'))" -->
                    <v-list-item-subtitle  v-if="(relevance.relevance_grade != null && !relevance?.transcriptionId?.toString()?.includes('$uid'))">
                      Grado rilevanza: 
                      <v-chip 
                        style="color: black !important;" 
                        :color="relevance.relevance_grade | relevanceGradeColor"
                      >
                        {{ 
                          relevance.relevance_grade | relevanceGradeLabel
                        }}
                      </v-chip>
                    </v-list-item-subtitle>
                    
                    <v-list-item-subtitle v-if="(relevance.synthesisId !== null && relevance.synthesis.relevance_grade != null && relevance.synthesisId !== null && !relevance.synthesisId.toString().includes('$uid'))">
                      Note rilevanza: 
                      <v-chip 
                        style="color: black !important;" 
                        :color="relevance.synthesis.relevance_grade | relevanceNoteColor"
                      >
                        {{
                          relevance.synthesis.relevance_grade | relevanceNoteLabel
                        }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action @click.stop="selectRelevance(relevance)">
                    <v-list-item-action-text>{{ relevance.type | relevanceActionLabel }}</v-list-item-action-text>
                    <v-icon style="font-size:27px;">mdi-information</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <div class="tooltiptext tooltip-info__container" v-if="(relevance.transcriptionId !== null && !relevance.transcriptionId.toString().includes('$uid')) || (relevance.synthesisId !== null && !relevance.synthesisId.toString().includes('$uid'))">
                  <div class="info__text">
                    <div v-if="relevance.transcriptionId !== null">
                      <p style="font-weight: bold;" class="">Trascrizione</p>
                      <p>{{ relevance.transcription.text }}</p>
                    </div>
                    <v-divider style="margin-top:10px;margin-bottom:10px;"></v-divider>
                    <div v-if="relevance.synthesisId != null">
                      <p style="font-weight: bold;">Sintesi</p>
                      <p>{{ relevance.synthesis.text }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <v-divider :key="'d' + relevance.id"></v-divider>
            </div>
            
          </v-list>
          <v-card-actions>
            <v-pagination 
              v-model="page" 
              :length="Math.ceil(relevanceLength / itemsPerPage)" 
              :total-visible="20" 
              prev-icon="mdi-menu-left" 
              next-icon="mdi-menu-right"
              @input="getData"
            >
            </v-pagination>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card>
          <v-subheader>Esporta Le rilevanze</v-subheader>
          <template>
            <div style="width:100%;display:flex;justify-content:center;">
              <div style="width:70%;">
                <v-text-field
                  v-model="itemsPerPage"
                  label="Quante rilevanze vuoi esportare?"
                  type="number"
                  outlined
                ></v-text-field>
                <!-- <v-btn color="green" block @click="exportRelevance()" :loading="pdfLoading" style="width:100%;">
                  <v-icon>mdi-export</v-icon>Esporta PDF
                </v-btn> -->
                <br>
                <v-btn color="green" block @click="exportWord()" :loading="exportWordLoad" style="width:100%;">
                  <v-icon>mdi-export</v-icon>Esporta Word
                </v-btn>
                <br>
                <v-btn color="green" block @click="exportWord(true)" :loading="exportWordLoad" style="width:100%;">
                  <v-icon>mdi-export</v-icon>Esporta Sintesi
                </v-btn>
                <br>
                <v-btn color="green" block @click="clearSocketData()" v-if="checkUsers()" style="width:100%;">
                  <v-icon>mdi-export</v-icon>Reset socket
                </v-btn>
              </div>
            </div>
          </template>
          <v-subheader style="margin-top:10px;">Inserisci il grado di rilevanza per le selezionate</v-subheader>
          <template>
            <div style="padding: 0 16px 0 16px;">
              <v-checkbox
                v-model="selectAll"
                :label="'Seleziona tutti'"
                color="primary"
                @change="selectAllRelevance"
              ></v-checkbox>
              <v-select
                v-on:change="changeRelevanceSavement"
                item-value="value"
                item-text="label"
                v-model="relevanceGradeSavement"
                :items="relevanceGrades"
              >
              </v-select>
              <!-- create checkbox with label "Seleziona tutti"-->
              <div style="padding-bottom:10px;">
                <v-btn color="primary" block @click="sendSelectedData()">
                  <v-icon>mdi-content-save</v-icon>
                  Salva
                </v-btn>
              </div>
            </div>
          </template>
        </v-card>
        <relevance-details v-if="selectedRelevance" :relevance="selectedRelevance" style="margin-top:10px;">
          <template v-slot:actions>
            <v-btn :to="`/device/relevance/${selectedRelevance.id}`" color="primary">
              <v-icon>{{ selectedRelevance.transcription ? 'mdi-pencil' : 'mdi-plus' }}</v-icon>
              {{ selectedRelevance.transcription ? 'Modifica' : 'Crea' }} trascrizione
            </v-btn>
          </template>
        </relevance-details>
      </v-col>
    </v-row>
  </div>
</template>

<script>
	import ContactActivityInfo from "@/components/contact/ContactActivityInfo";
	import DeviceInfo from "@/components/device/DeviceInfo";
	import Relevance from "@/store/models/relevance.model";
	import Avatar from "vue-avatar";
	import { apiUrl } from '@/common/endpoint.const';
	import { ADD_OBSERVER } from "@/store/actions.type";
	import TranscriptionDetails from "@/components/relevance/transcription/TranscriptionDetails";
	import FileDetails from "@/components/file/FileDetails";
	import RelevanceDetails from "@/components/relevance/RelevanceDetails";
	import { jsPDF } from "jspdf";
	import autoTable from 'jspdf-autotable';
	import moment from 'moment';
	import SocketioService from '../../services/socketio.service';
	import { addOneHour } from '../../common/utils';
	import {
		Document,
		Packer,
		Paragraph,
		TextRun,
		ImageRun,
		AlignmentType,
		TableCell,
		TableRow,
		Table,
	} from "docx"
	import Swal from 'sweetalert2';
	import { saveAs } from "file-saver";
	import axios from '../../axios';
	import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LPolyline } from "vue2-leaflet";
	import { latLng } from "leaflet";

	const secondToTime = (secondsFrom) => {
		var sec_num = parseInt(secondsFrom, 10); // don't forget the second param
		var hours = Math.floor(sec_num / 3600);
		var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
		var seconds = sec_num - (hours * 3600) - (minutes * 60);

		if (hours < 10) { hours = "0" + hours; }
		if (minutes < 10) { minutes = "0" + minutes; }
		if (seconds < 10) { seconds = "0" + seconds; }
		return hours + ':' + minutes + ':' + seconds;
	};

	const getDuration = (relevance, refs) => {
		if (isAudioRelevance(relevance.type)) {
			return secondToTime(refs.waveform.peaks.player.getDuration());
		}
		else if (relevance.type === 1) {
			return "0";
		}
		else if (relevance.type === 2) {
			return "0";
		}
	};

	const getRit = (config, deviceId) => {
		return config.rit.filter(d => d.device == deviceId)[0]?.value || "";
	};

	const isAudioRelevance = (type) => {
		return type === 0 || type === 3 || type === 4 || type === 5 || type === 10;
	}

	function imageHeight(url, maxWidth, maxHeight) {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => {
				const ratio = img.width / img.height;
				let height = maxWidth / ratio;
				if (height > maxHeight) {
					height = maxHeight;
				}
				resolve(height);
			};
			img.onerror = reject;
			img.src = url;
		});
	}



	export default {
		name: "DeviceRelevance",
		components: {
			ContactActivityInfo,
			DeviceInfo,
			TranscriptionDetails,
			FileDetails,
			RelevanceDetails,
			Avatar,
			LMap,
			LTileLayer,
			LMarker,
			LPopup,
			LTooltip,
			LPolyline,
		},
		data() {
			let filterLocal;
			try {
				filterLocal = JSON.parse(sessionStorage.getItem('filterJSON'));
			}
			catch (e) {
				filterLocal = null;
			}
			return {
				numberOfNotListenerRelevance: null,
				pdfLoading: false,
				loading: false,
				filterLocalJSON: filterLocal,
				relevanceData: [],
				apiUrl,
				socketArray: SocketioService.occupiedID,
				selectedRelevance: null,
				selectedRelevanceForGrade: [],
				itemsPerPage: 10,
				selectAll: false,
				selectElement: [],
				page: window.relevancePage.page,
				filterProgNumber: window.relevancePage.filterProgNumber,
				relevanceGrade: window.relevancePage.relevanceGrade,
				listenChoice: window.relevancePage.listenChoice,
				typeChoice: window.relevancePage.typeChoice,
				relevanceNote: {
					label: '',
					value: null
				},
				relevanceGrades: [
					{
						label: 'Molto importante',
						value: 5
					},
					{
						label: 'Utile ai fini dell\'indagine',
						value: 4
					},
					{
						label: 'Da valutare',
						value: 3
					},
					{
						label: 'Non inerente',
						value: 2
					},
					{
						label: 'Non utilizzabile',
						value: 1
					},
					{
						label: 'Non impostata',
						value: 0
					},
					{
						label: 'Tutte',
						value: null
					}
				],
				relevanceGradeSavement: {
					label: 'Non impostata',
					value: 0
				},
				filterDateFrom: window.relevancePage.dateFrom,
				filterTranscription: "",
				exportWordLoad: false,
				filterSynthesis: "",
				filterDateTo: window.relevancePage.dateTo,
				relevanceActualData: [],
				notIdRelevance: [],
				relevanceLength: 0,
				filterJSONQueryParams: {
					relevance: "",
					note: "",
					gestita: "",
					tipologia: "",
					dallaData: "",
					allaData: "",
					trascrizioneText: "",
					sintesiText: "",
					progressivo: ""
				},
				itemsPerPage: 10,
				listenChoices: [
					{ label: 'Si', value: true },
					{ label: 'No', value: false },
					{ label: 'Tutte', value: null }
				],
				relevanceNotes: [
					{
						label: 'Non impostato',
						value: 0
					},
					{
						label: 'Da trascrivere',
						value: 1
					},
					{
						label: 'Trascritta',
						value: 2
					},
					{
						label: 'Da tradurre',
						value: 3
					},
					{
						label: 'Valutare Traduzione',
						value: 4
					},
					{
						label: 'Info utile',
						value: 5
					},
					{
						label: 'Positiva',
						value: 6
					},
					{
						label: 'Rilevante',
						value: 7
					},
					{
						label: 'Attinente droga',
						value: 8
					},
					{
						label: 'Da riascoltare',
						value: 9
					},
					{
						label: 'Tutte',
						value: null
					}
				],
				typeChoices: [
					{ label: 'File audio', value: 0 },
					{ label: 'File video', value: 1 },
					{ label: 'File immagine', value: 2 },
					{ label: 'Registrazione ambientale', value: 3 },
					{ label: 'Audio chiamata Whatsapp', value: 4 },
					{ label: 'Audio chiamata Facebook', value: 8 },
					{ label: 'Audio chiamata Telegram', value: 5 },
					{ label: 'Audio chiamata Instagram', value: 10 },
					{ label: 'Keylog', value: 14 },
					{ label: 'Posizione', value: 15 },
					{ label: 'Documenti', value: "documents-filter" },
					{ label: 'Tutte', value: null }
				],
				gradeToSave: 0,
				filterJSONToSend: {
					filter_relevance: null,
					filter_note: null,
					filter_managing: null,
					filter_type: null,
					filter_date_from: null,
					filter_date_to: null,
					filter_transcription: null,
					filter_synthesis: null,
					filter_prog: null
				},
				center: latLng(0, 0),
			}
		},
		methods: {
			handleBroadcastIdValue(data) {
				console.log("DATI ARRIVATI");
				SocketioService.elaborateData(data);
				this.socketArray = SocketioService.occupiedID;
			},

			async handleUpdateDataRelevance(data) {
				console.log("DATI RELEVANCE UPDATE");
				await this.getData("9-DEVICE");
			},

			centerMapOnPosition(position) {
				console.log("position: ", latLng(position.lat, position.lon));
				this.center = latLng(position.lat, position.lon);
			},
			withTooltip() {
				if (this.device && this.device.positions && this.device.positions.length > 0)
					return latLng(this.device.positions[this.device.positions.length - 1].lat, this.device.positions[this.device.positions.length - 1].lon)
				return latLng(0, 0);
			},
			polyline() {
				return {
					latlngs: [
						...this.pageItems
					],
					color: "green"
				}
			},
			showMap() {
				return this.pageItems.length > 0
			},
			selectRelevance(relevance) {
				if (this.selectedRelevance != relevance) {
					this.selectedRelevance = relevance;
				} else {
					this.selectedRelevance = null;
				}
			},
			ProxyAddOneHour(date, type) {
				return addOneHour(date, type == 3);

			},
			checkUsers() {
				return JSON.parse(sessionStorage.getItem('user')).username == 'testUser';
			},
			escapeRegExp(string) {
				return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
			},
			getUserOccupied(idRelevance) {
				let arrID = [];
				// let users = "";
				let users = [];
				console.log("SocketArr", this.socketArray);
				for (let i = 0; i < this.socketArray.length; i++) {
					console.log("SocketArr[i]: ", this.socketArray[i]);
					if (this.socketArray[i] == idRelevance) {
						arrID.push(i);
					}
				}
				console.log(SocketioService.occupiedUsers);
				for (let i = 0; i < arrID.length; i++) {
					// i + 1 == arrID.length ? users += SocketioService.occupiedUsers[arrID[i]] : users += SocketioService.occupiedUsers[arrID[i]]+",";
					users.push(SocketioService.occupiedUsers[arrID[i]]);
				}
				console.log("users: ", users)
				return users;
			},
			goToTranscription(relevance) {
				if (this.relevanceNote != null && this.relevanceNote.value != null) {
					this.filterJSONQueryParams.note = JSON.parse(JSON.stringify(this.relevanceNote));
				}
				sessionStorage.setItem('filterJSON', JSON.stringify(this.filterJSONQueryParams));
				this.$router.push(`/device/relevance/${relevance.id}`);
			},
			selectSingleRelevanceForGrade(index, id) {
				console.log("selectAll: ", this.selectAll);
				if (!this.selectAll) {
					if (this.selectedRelevanceForGrade.includes(id)) {
						let index = this.selectedRelevanceForGrade.indexOf(id);
						this.selectedRelevanceForGrade.splice(index, 1);
						this.selectElement[this.page][index] = false;
					}
					else {
						this.selectedRelevanceForGrade.push(id);
						this.selectElement[this.page][index] = true;
					}
				}
				else {
					if (this.notIdRelevance.includes(id)) {
						let index = this.notIdRelevance.indexOf(id);
						this.notIdRelevance.splice(index, 1);
					}
					else {
						this.notIdRelevance.push(id);
					}
				}
			},
			async changeRelevance(a) {
				this.relevance.relevance_grade = a;
			},
			async changeRelevanceSavement(a) {
				this.gradeToSave = a;
			},
			resetFilterData() {
				this.filterDateFrom = null;
				this.filterDateTo = null;
			},
			clearSocketData() {
				SocketioService.clearData();
			},
			checkIfUserIsSame(idRelevance) {
				let users = this.getUserOccupied(idRelevance);
				let localUser = JSON.parse(sessionStorage.getItem("user")).username;
				console.log(users[0], "==", localUser);
				return users[0] == localUser;
			},
			selectAllRelevance(event) {
				this.selectElement[this.page].map((element, index) => {
					this.selectElement[this.page][index] = event;
				});
				if (!event) {
					this.selectedRelevanceForGrade.splice(0, this.selectedRelevanceForGrade.length);
				}
			},
			resetDataSelected() {
				this.selectedRelevanceForGrade.splice(0, this.selectedRelevanceForGrade.length);
				this.notIdRelevance.splice(0, this.notIdRelevance.length);
				this.selectAll = false;
				if (this.selectElement[this.page] != undefined || this.selectElement[this.page] != null) {
					this.selectElement[this.page].map((element, index) => {
						this.selectElement[this.page][index] = false;
					});
				}
			},
			async sendSelectedData() {
				if (this.selectAll && this.relevanceLength == 0) {
					Swal.fire({
						title: 'Errore',
						text: 'Non ci sono rilevanze da selezionare',
						icon: 'error',
						confirmButtonText: 'Ok'
					});
					return;
				}
				if (this.selectedRelevanceForGrade.length == 0 && !this.selectAll) {
					// inserti notification
					Swal.fire({
						title: 'Errore',
						text: 'Selezionare almeno una rilevanza',
						icon: 'error',
						confirmButtonText: 'Ok'
					});
					return;
				}
				Swal.fire({
					title: "Attenzione!",
					text: "Cliccando su salva cambierai tutte le rilevanze delle acquisizioni selezionate, sei sicuro di voler procedere?",
					icon: "warning",
					showDenyButton: true,
					confirmButtonText: "Salva",
					denyButtonText: `Cancella`
				}).then(async (result) => {
					if (result.isConfirmed) {
						let data = {
							selectedAll: this.selectAll,
							idRelevance: this.selectedRelevanceForGrade,
							filter: this.filterJSONToSend,
							relevanceGrade: this.gradeToSave,
							notIdRelevance: this.notIdRelevance
						}
						const rev = await Relevance.api().post(`${apiUrl}/device/relevance/set/relevance/grade/from-id`, data);
						let response = rev.response.data;
						if (response.status) {
							Swal.fire({
								title: 'Successo',
								text: response.message,
								icon: 'success',
								confirmButtonText: 'Ok'
							});
							this.resetDataSelected();
							this.getData();

							return;
						}
						else {
							Swal.fire({
								title: 'Errore',
								text: response.message,
								icon: 'error',
								confirmButtonText: 'Ok'
							});
						}
					} else if (result.isDenied) {
						Swal.fire("I cambiamenti non sono stati salvati!", "", "info");
					}
				});

			},
			/*paginateData(){
				if(this.filterProgNumber != undefined && this.filterProgNumber.toString() > 0){
					this.relevanceLength = 1;
				}
				else{
					this.relevanceLength = this.relevanceActualData.length;
				}
				console.log(this.page);
				window.relevancePage.page = this.page;
				const start = (this.page - 1) * this.relevanceLength;
				const end = start + this.itemsPerPage;
				this.relevanceData = this.relevanceData.slice(start, end); 
			  
			},*/
			rotateImage(fileName, degrees) {
				return new Promise((resolve, reject) => {
					const img = new Image();
					img.src = fileName;
					img.crossOrigin = "anonymous";
					img.onload = () => {
						const canvas = document.createElement('canvas');
						const ctx = canvas.getContext('2d');
						canvas.width = img.width;
						canvas.height = img.height;
						ctx.translate(canvas.width / 2, canvas.height / 2);
						ctx.rotate(this.degreesToRadians(degrees));
						ctx.drawImage(img, -img.width / 2, -img.height / 2);
						resolve(canvas.toDataURL());
					};
				});
			},
			degreesToRadians(degrees) {
				return degrees * (Math.PI / 180);
			},
			async getData(debug = "") {
				console.log("DEBUG:", debug);
				/*Device.dispatch(FETCH_DEVICE_RELEVANCE,
				{
					deviceId: sessionStorage.getItem("deviceId"),
					limit: 50
				});*/
				if (this.itemsPerPage > 100) {
					Swal.fire({
						title: 'Errore',
						text: 'Non è possibile visualizzare più di 100 rilevanze per volta',
						icon: 'error',
						confirmButtonText: 'Ok'
					});
					this.itemsPerPage = 100;
				}
				console.log("GET DATA RELEVANCE");
				this.loading = true;
				const encodedString = encodeURIComponent(JSON.stringify(this.filterJSONToSend));
				const result = await Relevance.api().post(`${apiUrl}/device/relevance`, {
					page: this.page,
					limit: this.itemsPerPage,
					filter: this.filterJSONToSend,
					persistBy: 'create',
					persistOptions: {
						create: ['files', 'transcriptions', 'synthesis', 'relevanceEvents', 'users'],
					}
				});
				this.relevanceData = result.response.data.response;
				// push false data in selectElement array for length of relevance
				this.selectElement[this.page] = [];
				this.relevanceData.forEach((element) => {
					this.selectElement[this.page].push(this.selectAll);
				});
				console.log("this.selectElement:", this.selectElement)
				this.relevanceLength = result.response.data.count;
				this.$set(this, 'relevanceData', this.relevanceData);
				this.relevanceActualData = this.relevanceData;
				console.log(this.relevanceActualData);
				this.loading = false;

			},

			async exportRelevance() {
				try {
					this.pdfLoading = true;
					for (const element of this.relevanceData) {
						let maxHeight = undefined;
						const doc = new jsPDF();
						doc.addImage(window.config.reportImageLogo, "JPEG", 95, 10, 20, 20);
						doc.setFont("times", "bold");
						doc.setFontSize(28);
						doc.text(window.config.legione, 105, 40, null, null, "center");
						doc.setFont("times", "normal");
						doc.setFontSize(20);
						doc.text(window.config.comando, 105, 50, null, null, "center");
						doc.setFontSize(18);
						doc.text(window.config.nucleo, 105, 60, null, null, "center");
						doc.setFontSize(15);
						const text = window.config.oggetto;
						const width = doc.internal.pageSize.getWidth() - 40; // larghezza massima disponibile per il testo
						const lines = doc.splitTextToSize(text, width);
						doc.setFontSize(15);
						doc.text(20, 70, lines);
						doc.addImage(window.config.reportImageDivider, "JPEG", 80, 95, 50, 10);
						let actualY = 0;
						autoTable(
							doc,
							{
								startY: 110,
								tableLineColor: [0, 0, 0],
								tableLineWidth: 0.1,
								styles: {
									lineColor: [0, 0, 0],
									lineWidth: 0.1,
								},
								headStyles: { textColor: [0, 0, 0], halign: 'center', fillColor: [210, 210, 210] }, head: [['Progr.', 'Data e ora', 'Durata', 'Bersaglio', 'P.P - RIT"']],
								theme: 'grid',
								body: [[
									{ content: element.id, styles: { halign: 'center', fillColor: [255, 255, 255] } },
									{ content: moment(element.file.created_at).format('DD/MM/YYYY HH:mm'), styles: { halign: 'center', fillColor: [255, 255, 255] } },
									{ content: "", styles: { halign: 'center', fillColor: [255, 255, 255] } },
									{ content: 'IMEI:' + element.device.imei1, styles: { halign: 'center', fillColor: [255, 255, 255] } },
									{ content: getRit(window.config, element.device.id), styles: { halign: 'center', fillColor: [255, 255, 255] } }
								],
								],
							}
						);
						actualY = doc.lastAutoTable.finalY;
						const arrayRelevanceType = [1, 2];
						if (element?.transcription?.user?.username) {
							doc.autoTable({
								startY: actualY,
								columnStyles: {
									0: {
										cellWidth: 40
									}
								},
								theme: 'grid',
								bodyStyles: {
									lineColor: [0, 0, 0]
								},
								body: [
									[
										{
											content: 'Esportato il: ' + moment().format('DD/MM/YYYY HH:mm:ss'),
											styles: {
												fillColor: [210, 210, 210],
												textColor: [0, 0, 0]
											}
										},
										{
											content: ' Operatore di PG: testUser ' + element.transcription.user.username,
											styles: {
												fillColor: [255, 255, 255],
												textColor: [0, 0, 0]
											}
										}
									]
								],
							});
							actualY = doc.lastAutoTable.finalY;
						}
						if (element?.transcription?.interlocutors) {
							autoTable(
								doc,
								{
									startY:
										doc.lastAutoTable.finalY, columnStyles: {
											0: { cellWidth: 40 }
										},
									theme: 'grid',
									bodyStyles: { lineColor: [0, 0, 0] },
									body: [
										[
											{ content: 'Interlocutori', styles: { fillColor: [210, 210, 210], textColor: [0, 0, 0] } },
											{ content: element.transcription.interlocutors ? element.transcription.interlocutors.join() : "", styles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] } }
										]
									],
								}
							);
							actualY = doc.lastAutoTable.finalY;
						}
						else if (element.type == 2) {
							const maxWidth = 110; // Larghezza massima dell'immagine
							const fileName = apiUrl + '/downloadfile/' + element.file.name + `?access_token=${sessionStorage.getIte("token")}&deviceId=${sessionStorage.getItem("deviceId")}`;
							const x = 30;
							const y = doc.lastAutoTable.finalY + 10;
							maxHeight = doc.internal.pageSize.getHeight() - y; // Calcola l'altezza massima dell'immagine

							const height = await imageHeight(fileName, maxWidth, maxHeight); // Calcola l'altezza dell'immagine
							actualY += height;
							console.log("fileName: ", fileName);
							doc.addImage(
								fileName,
								"JPEG",
								x,
								0,
								maxWidth,
								height,
								"",
								"NONE", // Aggiungi questo parametro per specificare l'orientamento dell'immagine
								270
							);
						}
						if (element?.transcription?.text) {
							autoTable(doc, {
								startY: actualY + 20, theme: 'grid', bodyStyles: { lineColor: [0, 0, 0] }, body: [
									[{ content: element.transcription.text, styles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] } }]
								]
							});
						}
						if (maxHeight != undefined && element?.transcription == undefined) {
							actualY = 270;
						}
						else {
							actualY = doc.lastAutoTable.finalY + 45;
						}
						doc.setFontSize(10);
						doc.text(105, actualY, "L'UFFICIALE DI P.G.", null, null, "center");
						doc.text(105, actualY + 10, "_______________________", null, null, "center");
						doc.save(element.id + ".pdf");
					}
					console.log("this.pdfLoading: ", this.pdfLoading);
				}
				catch (e) {
					console.log(e);
				}
				finally {
					this.pdfLoading = false;
				}
			},
			async exportWord(synthesisPrint = false) {
				Swal.fire({
					title: 'Attenzione',
					text: 'Il documento Word potrebbe richiedere del tempo per essere generato, si desidera procedere?',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonText: 'Si',
					cancelButtonText: 'No'
				}).then(async (result) => {
					try {
						if (result.isConfirmed) {
							this.exportWordLoad = true;
							let RIT;
							let allChildren = [];
							let today = new Date();
							let dd = today.getDate();
							let mm = today.getMonth() + 1;
							let yyyy = today.getFullYear();
							let hh = today.getHours();
							let min = today.getMinutes();
							var exportDocumentInfos = (await axios.get(`${apiUrl}/device/get-document-export`)).data;
							let info_modulo = exportDocumentInfos.info_modulo
								.replace(new RegExp(this.escapeRegExp('{{anno}}'), 'g'), yyyy)
								.replace(new RegExp(this.escapeRegExp('{{mese}}'), 'g'), mm)
								.replace(new RegExp(this.escapeRegExp('{{giorno}}'), 'g'), dd)
								.replace(new RegExp(this.escapeRegExp('{{orario}}'), 'g'), hh + ":" + min)
								.replace(new RegExp(this.escapeRegExp('{{firmatario}}'), 'g'), exportDocumentInfos.firmatario);
							let oggettoTable = new TableRow({ children: [] });

							const firmaText = new Paragraph({
								alignment: AlignmentType.CENTER,
								children: [
									new TextRun({
										text: exportDocumentInfos.titolo_firma,
										size: 25,
										color: '000000',
									}),
								],
							});

							// Creazione del paragrafo per la linea
							const linea = new Paragraph({
								alignment: AlignmentType.CENTER,
								children: [
									new TextRun({
										text: '____________________',
										bold: true,
										size: 24.5,
										color: '000000',
									}),
								],
							});

							// Creazione del paragrafo per il firmatario
							const firmatario = new Paragraph({
								alignment: AlignmentType.CENTER,
								children: [
									new TextRun({
										text: exportDocumentInfos.firmatario,
										size: 24.5,
										color: '000000',
									}),
								],
							});
							let saveIdExport = [];
							for (let i = this.relevanceData.length - 1; i >= 0; i--) {
								let element = this.relevanceData[i];
								saveIdExport.push(element.id);

								let relevanceDate = new Date(element.created_at).toLocaleString();
								let infoTable = new Table({
									columnWidths: [4500, 4500], // Larghezza delle colonne
									rows: [
										// Intestazione grigia
										new TableRow({
											cantSplit: true, // Impedisce lo spezzamento della riga tra le pagine
											children: [
												new TableCell({
													columnSpan: 2, // Span su entrambe le colonne
													shading: {
														fill: 'D9D9D9', // Colore di riempimento grigio
													},
													children: [
														new Paragraph({
															alignment: AlignmentType.CENTER,
															children: [
																new TextRun({
																	text: RIT,
																	bold: true,
																	size: 24.5,
																	color: '000000',
																}),
															],
														}),
													],
												}),
											],
										}),

										// Righe con identificatori di riga e colonna
										new TableRow({
											children: [
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: 'Progressivo',
																	size: 24.5,
																	bold: true,
																	color: '000000',
																}),
															],
														}),
													],
												}),
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: element.id.toString(),
																	size: 24.5,
																	color: '000000',
																}),
															],
														}),
													],
												}),
											],
										}),
										new TableRow({
											children: [
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: 'Durata',
																	size: 24.5,
																	bold: true,
																	color: '000000',
																}),
															],
														}),
													],
												}),
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: '',
																	size: 24.5,
																	color: '000000',
																}),
															],
														}),
													],
												}),
											],
										}),
										new TableRow({
											children: [
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: 'TIPO',
																	size: 24.5,
																	bold: true,
																	color: '000000',
																}),
															],
														}),
													],
												}),
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: element ? "Captatore informatico – " + this.getTypeOfFileByValue(element) : "",
																	size: 24.5,
																	color: '000000',
																}),
															],
														}),
													],
												}),
											],
										}),
										new TableRow({
											children: [
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: 'Data/Ora',
																	size: 24.5,
																	bold: true,
																	color: '000000',
																}),
															],
														}),
													],
												}),
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: relevanceDate,
																	size: 24.5,
																	color: '000000',
																}),
															],
														}),
													],
												}),
											],
										}),
										new TableRow({
											children: [
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: 'Interprete Traduttrice Lingua',
																	size: 24.5,
																	bold: true,
																	color: '000000',
																}),
															],
														}),
													],
												}),
												new TableCell({
													children: [
														new Paragraph({
															children: [
																new TextRun({
																	text: '',
																	size: 24.5,
																	color: '000000',
																}),
															],
														}),
													],
												}),
											],
										}),
									],
								});
								let transcriptionParagraph = new Paragraph({ children: [] });
								let ImageParagraph = new Paragraph({ children: [] });

								let oggetto = exportDocumentInfos.oggetto;
								if (oggetto.length > 0) {
									oggetto = oggetto
										.replace(new RegExp(this.escapeRegExp('{{progressivo}}'), 'g'), element.id);
									oggettoTable = new TableRow({
										children: [
											new TableCell({
												children: [
													new Paragraph({
														children: [
															new TextRun({
																text: "Oggetto",
																bold: true,
																color: '000000',
																size: 24.5,
															}),
														]
													}),
												]
											}),
											new TableCell({
												children: [
													new Paragraph({
														children: [
															new TextRun({
																text: oggetto,
																color: '000000',
																size: 24.5,
															}),
														]
													}),
												]
											}),
										]
									})
								}

								if (element.type == 3) {
									info_modulo = info_modulo.replace(new RegExp(this.escapeRegExp('{{RIT}}'), 'g'), exportDocumentInfos.rit_acquisizioni_ambientali);
									RIT = exportDocumentInfos.info_proc.replace(new RegExp(this.escapeRegExp('{{RIT}}'), 'g'), exportDocumentInfos.rit_acquisizioni_ambientali);
								}
								else {
									info_modulo = info_modulo.replace(new RegExp(this.escapeRegExp('{{RIT}}'), 'g'), exportDocumentInfos.rit_acquisizioni);
									RIT = exportDocumentInfos.info_proc.replace(new RegExp(this.escapeRegExp('{{RIT}}'), 'g'), exportDocumentInfos.rit_acquisizioni);
								}
								if (element.transcription && element.transcription.text) {
									transcriptionParagraph = new Paragraph({
										children: [
											// insert transcription text
											new TextRun({
												text: "Trascrizione: " + element.transcription.text,
												size: 24.5,
												font: {
													name: 'Calibri',
													size: 24,
												},
											}),
										]
									})
								}
								if (synthesisPrint && element.synthesis && element.synthesis.text) {
									transcriptionParagraph = new Paragraph({
										children: [
											// insert transcription text
											new TextRun({
												text: "Sintesi: " + element.synthesis.text,
												size: 24.5,
												font: {
													name: 'Calibri',
													size: 24,
												},
											}),
										]
									})
								}
								if (element.type == 2) {
									let fileName = apiUrl + '/downloadfile/' + element.file.name + `?access_token=${sessionStorage.getItem("token")}&deviceId=${sessionStorage.getItem("deviceId")}`;
									const degrees = 0; // Numero di gradi di rotazione desiderati
									const rotatedImageData = await this.rotateImage(fileName, degrees);
									const image = new ImageRun({
										data: rotatedImageData,
										transformation: {
											width: 300,
											height: 300,
										},
										alignment: AlignmentType.CENTER,
									});
									ImageParagraph = new Paragraph({
										alignment: AlignmentType.CENTER,
										children: [
											image,
										],
									});
								}
								// Creazione del paragrafo per il testo "Ufficiale di P.G."
								let children = [
									// Tabella Oggetto
									new Table({
										columnWidths: [
											1700,
											6700,
										],
										rows: [
											oggettoTable
										]
									}),
									// balnk space
									new Paragraph({
										alignment: AlignmentType.CENTER,
										children: [
											new TextRun({
												text: "",
												color: '000000',
												size: 24.5,
											}),
										]
									}),
									// Tabella contenente le informazioni inerenti al caso
									// balnk space
									new Paragraph({
										alignment: AlignmentType.CENTER,
										children: [
											new TextRun({
												text: "",
												color: '000000',
												size: 24.5,
											}),
										]
									}),
									infoTable,
									// balnk space
									new Paragraph({
										alignment: AlignmentType.CENTER,
										children: [
											new TextRun({
												text: "",
												color: '000000',
												size: 24.5,
											}),
										]
									}),
									transcriptionParagraph,
									ImageParagraph,
									// balnk space
									new Paragraph({
										alignment: AlignmentType.CENTER,
										children: [
											new TextRun({
												text: "",
												color: '000000',
												size: 50,
											}),
										]
									}),
									new Paragraph({
										alignment: AlignmentType.CENTER,
										children: [
											new TextRun({
												text: "",
												color: '000000',
												size: 50,
											}),
										]
									}),
								];
								allChildren.push(children);
							}
							const doc = new Document({
								sections: [{
									properties: {},
									children: [
										// logo iniziale centrale
										new Paragraph({
											alignment: AlignmentType.CENTER,
											children: [
												new ImageRun({
													data: window.config.reportImageLogo,
													transformation: {
														width: 100,
														height: 100,
													},
													alignment: AlignmentType.CENTER,
												}),
											],
										}),
										// GUARDIA DI FINANZA
										new Paragraph({
											alignment: AlignmentType.CENTER,
											children: [
												new TextRun({
													text: exportDocumentInfos.titolo,
													bold: true,
													color: '000000',
													size: 36,
												}),
											]
										}),
										// titolo Gruppo
										new Paragraph({
											alignment: AlignmentType.CENTER,
											children: [
												new TextRun({
													text: exportDocumentInfos.gruppo,
													bold: true,
													color: '000000',
													size: 24,
												}),
											]
										}),
										// Nucleo Operativo
										new Paragraph({
											alignment: AlignmentType.CENTER,
											children: [
												new TextRun({
													text: exportDocumentInfos.tipo_nucleo,
													color: '000000',
													size: 19,
												}),
											]
										}),
										// informazioni varie
										new Paragraph({
											alignment: AlignmentType.CENTER,
											children: [
												new TextRun({
													text: exportDocumentInfos.informazioni_varie,
													color: '000000',
													size: 24.5,
												}),
											]
										}),
										// balnk space
										new Paragraph({
											alignment: AlignmentType.CENTER,
											children: [
												new TextRun({
													text: "",
													color: '000000',
													size: 24.5,
												}),
											]
										}),
										// Tabella Oggetto
										new Table({
											columnWidths: [
												1700,
												6700,
											],
											rows: [
												oggettoTable
											]
										}),
										// balnk space
										new Paragraph({
											alignment: AlignmentType.CENTER,
											children: [
												new TextRun({
													text: "",
													color: '000000',
													size: 24.5,
												}),
											]
										}),
										// Testo contenente informazioni inerenti al caso
										new Paragraph({
											alignment: AlignmentType.JUSTIFIED,
											children: [
												new TextRun({
													text: info_modulo,
													color: '000000',
													size: 24.5,
												}),
											]
										}),
										...allChildren.flat(),
										// Tabella contenente le informazioni inerenti al caso
										// balnk space
										new Paragraph({
											alignment: AlignmentType.CENTER,
											children: [
												new TextRun({
													text: "",
													color: '000000',
													size: 24.5,
												}),
											]
										}),
										// balnk space
										new Paragraph({
											alignment: AlignmentType.CENTER,
											children: [
												new TextRun({
													text: "",
													color: '000000',
													size: 24.5,
												}),
											]
										}),
										// balnk space
										new Paragraph({
											alignment: AlignmentType.CENTER,
											children: [
												new TextRun({
													text: "",
													color: '000000',
													size: 50,
												}),
											]
										}),
										new Paragraph({
											alignment: AlignmentType.CENTER,
											children: [
												new TextRun({
													text: "",
													color: '000000',
													size: 50,
												}),
											]
										}),
										// Testo Conferma firma
										new Paragraph({
											alignment: AlignmentType.JUSTIFIED,
											children: [
												new TextRun({
													text: exportDocumentInfos.footer,
													color: '000000',
													size: 24.5,
												}),
											]
										}),
										// blank space
										new Paragraph({
											alignment: AlignmentType.CENTER,
											children: [
												new TextRun({
													text: "",
													color: '000000',
													size: 24.5,
												}),
											]
										}),
										// Firma 
										firmaText,
										// balnk space
										new Paragraph({
											alignment: AlignmentType.CENTER,
											children: [
												new TextRun({
													text: "",
													color: '000000',
													size: 24.5,
												}),
											]
										}),
										linea,
										firmatario
									]
								}]
							});
							Packer.toBlob(doc).then(blob => {
								saveAs(blob, "export.docx");
								this.exportWordLoad = false;
							}).then(() => {
								axios.post(`${apiUrl}/device/relevance/set-is-export`, { relevanceIDS: saveIdExport }).then((response) => {
									this.getData();
								});
							});
						}
					}
					catch (e) {
						console.log("e: ", e);
						Swal.fire({
							title: "Errore!",
							text: "Si è verificato un errore durante l'esportazione",
							icon: "error",
							confirmButtonText: "Ok"
						});
					}
					finally {
						this.exportWordLoad = false;
					}
				});
			},
			getTypeOfFileByValue(element) {
				if (element?.file?.name) {
					let fileName = element?.file?.name;
					console.log("FILENAME: ", fileName);
					if (fileName) {
						if (fileName.includes("IMG") && fileName.includes("WA")) {
							return "Immagine WhatsApp";
						}
						if (fileName.includes("PTT") && fileName.includes("WA") && fileName.includes(".opus")) {
							return "Vocale WhatsApp";
						}
					}
				}

				if (element?.type) {
					let value = element?.type;
					console.log("VALUE: ", value);
					let type = this.typeChoices.find((type) => type.value == value);
					return type.label;
				}
				return "";
			},
			insertFilterFromLocal() {
				console.log("this.filterLocalJSON: ", this.filterLocalJSON);
				if (this.filterLocalJSON != null) {
					this.relevanceGrade = this.filterLocalJSON.relevance;
					this.filterRelevance(this.relevanceGrade, null);

					this.relevanceNote = this.filterLocalJSON.note;
					this.filterNote(this.relevanceNote, null);

					this.listenChoice = this.filterLocalJSON.gestita;
					this.filterListenChoice(this.listenChoice, null);

					this.typeChoice = this.filterLocalJSON.tipologia;
					this.filterTypeChoice(this.typeChoice, null);

					this.filterDateFrom = this.filterLocalJSON.dallaData;
					this.filterDateFromFunc(this.filterDateFrom, null);

					this.filterTranscription = this.filterLocalJSON.trascrizioneText;
					this.filterTranscriptionFunc(this.filterTranscription, null);

					this.filterProgNumber = this.filterLocalJSON.progressivo;
					this.filterProgNumberFunc(this.filterProgNumber, null);

					this.filterSynthesis = this.filterLocalJSON.sintesiText;
					this.filterSynthesisFunc(this.filterSynthesis, null);

					this.filterDateTo = this.filterLocalJSON.allaData;
					this.filterDateToFunc(this.filterDateTo, null);
				}
			},
			filterRelevance(actual, old) {
				if (actual !== old) {
					console.log(actual, old);
					this.filterJSONToSend.filter_relevance = actual.value;
					this.filterJSONQueryParams.relevance = JSON.parse(JSON.stringify(actual));
					console.log("this.filterJSONToSend.filter_relevance: ", this.filterJSONToSend.filter_relevance)
					this.page = 1;
					this.resetDataSelected();
					this.getData("1-DEVICE");
				}
			},
			filterNote(actual, old) {
				if (actual !== old) {
					this.filterJSONToSend.filter_note = actual.value;
					this.filterJSONQueryParams.note = JSON.parse(JSON.stringify(actual));
					console.log("this.filterJSONToSend.filter_note: ", this.filterJSONToSend.filter_note)
					this.page = 1;

					this.resetDataSelected();
					this.getData("2-DEVICE");
				}
			},
			filterListenChoice(actual, old) {
				if (actual !== old) {
					this.filterJSONQueryParams.gestita = actual;
                    this.filterJSONToSend.filter_managing = actual.value;
                    console.log("this.filterJSONToSend.filter_managing: ", this.filterJSONToSend.filter_managing)
					this.page = 1;

					this.resetDataSelected();
					this.getData("3-DEVICE");
				}
			},
			filterTypeChoice(actual, old) {
				if (actual !== old) {
					this.filterJSONQueryParams.tipologia = actual;
					this.filterJSONToSend.filter_type = actual.value;
					console.log("this.filterJSONToSend.filter_type: ", this.filterJSONToSend.filter_type)
					this.page = 1;

					this.resetDataSelected();
					this.getData("4-DEVICE");
				}
			},
			filterDateFromFunc(actual, old) {
				if (actual !== old) {
					this.filterJSONQueryParams.dallaData = actual;
					this.filterJSONToSend.filter_date_from = actual;
					console.log("this.filterJSONToSend.filter_type: ", this.filterJSONToSend.filter_date_from)
					this.page = 1;

					this.resetDataSelected();
					this.getData("5-DEVICE");
				}
			},
			filterTranscriptionFunc(actual, old) {
				if (actual !== old) {
					this.filterJSONQueryParams.trascrizioneText = actual;
					this.filterJSONToSend.filter_transcription = actual;
					console.log("this.filterJSONToSend.filter_transcription: ", this.filterJSONToSend.filter_transcription);
					this.page = 1;

					this.resetDataSelected();
					this.getData("6-DEVICE");
				}
			},
			filterProgNumberFunc(actual, old) {
				if (actual !== old) {
					this.filterJSONToSend.filter_prog = actual;
					this.filterJSONQueryParams.progressivo = actual;
					console.log("this.filterJSONToSend.filter_prog: ", this.filterJSONToSend.filter_prog);
					this.page = 1;

					this.resetDataSelected();
					this.getData("6-DEVICE");
				}
			},
			filterSynthesisFunc(actual, old) {
				if (actual !== old) {
					this.filterJSONQueryParams.sintesiText = actual;
					this.filterJSONToSend.filter_synthesis = actual;
					console.log("this.filterJSONToSend.filter_synthesis: ", this.filterJSONToSend.filter_synthesis);
					this.page = 1;

					this.resetDataSelected();
					this.getData("7-DEVICE");
				}
			},
			filterDateToFunc(actual, old) {
				if (actual !== old) {
					this.filterJSONQueryParams.allaData = actual;
					this.filterJSONToSend.filter_date_to = actual;
					console.log("this.filterJSONToSend.filter_type: ", this.filterJSONToSend.filter_date_to)
					this.page = 1;

					this.resetDataSelected();
					this.getData("8-DEVICE");
				}
			},
			async getNumberOfNotListenerRelevance() {
				await axios.get(`${apiUrl}/device/getNumberOfNotListenRelevance`).then((data) => {
					console.log("data: ", data.data);
					this.numberOfNotListenerRelevance = data.data.count
				});
			}
		},
		watch: {
			relevanceGrade(actual, old) {
				this.filterRelevance(actual, old);
			},
			itemsPerPage(actual, old) {
				if (actual !== old) {
					this.page = 1;
					this.resetDataSelected();
					this.getData();
				}
			},
			relevanceNote(actual, old) {
				this.filterNote(actual, old);
			},
			socketArray(actual, old) {
				if (actual !== old) {
					console.log("ACTUAL CHANGE", actual);
				}
			},
			listenChoice(actual, old) {
				this.filterListenChoice(actual, old);
			},
			typeChoice(actual, old) {
				this.filterTypeChoice(actual, old);
			},
			filterDateFrom(actual, old) {
				this.filterDateFromFunc(actual, old);
			},
			filterTranscription(actual, old) {
				this.filterTranscriptionFunc(actual, old);
			},
			filterProgNumber(actual, old) {
				this.filterProgNumberFunc(actual, old);
			},
			filterSynthesis(actual, old) {
				this.filterSynthesisFunc(actual, old);
			},
			filterDateTo(actual, old) {
				this.filterDateToFunc(actual, old);
			},
		},
		computed: {
			device() {
				/*const queryDevice = Device.query()
				.with('state')      
				.with('relevance', (query) => {
					query.orderBy('created_at', 'desc');
					if (this.listenChoice.value !== null) {
						if (this.listenChoice.value) {
							query.where('transcriptionId', (value) => value);
						}
						else if (!this.listenChoice.value) {
							query.where('transcriptionId', (value) => !value);
						}
					}
				  
					if (this.filterTranscription.length > 0) {
						query.whereHas('transcription', (query2) => {    
							query2.orWhere('text', (value) => value.toLowerCase().includes(this.filterTranscription.toLowerCase()));
						})
					}
					if (this.filterSynthesis.length > 0) {
						query.whereHas('synthesis', (query2) => {    
							query2.orWhere('text', (value) => value.toLowerCase().includes(this.filterSynthesis.toLowerCase()));
						})
					}
				  
					if (this.typeChoice.value !== null) {
						query.where('type', (value) => value === this.typeChoice.value);
					}
				  
					if (this.relevanceNote !== null && this.relevanceNote.value !== null) {
						query.whereHas('synthesis', (query2) => {    
							query2.orWhere('relevance_grade', this.relevanceNote.value);
						})
					}
				  
					if (this.filterDateFrom !== null) {
						query.where('created_at', (value) => new moment(new Date(value)).format("X") >= new moment(this.filterDateFrom).format("X"));
					}
				  
					if (this.filterDateTo !== null) {
						query.where('created_at', (value) => new moment(new Date(value)).format("X") <= new moment(this.filterDateTo).format("X"));
					}
					if (this.relevanceGrade.value !== null) {
						query.where('relevance_grade', (value) => value === this.relevanceGrade.value);
					}
				  
				})
				.with('relevance.*')
				.with('relevance.transcription.events')
				.with('relevance.transcription.user')
				.find(sessionStorage.getItem("deviceId"));*/
				return [];
			},

			pageItems() {
				// console.log(this.filterProgNumber);

				/*const start = (this.page - 1) * this.itemsPerPage;
				const end = start + this.itemsPerPage;
				window.relevancePage.page = this.page;            
				window.relevancePage.relevanceGrade = this.relevanceGrade;
				window.relevancePage.dateFrom = this.filterDateFrom;
				window.relevancePage.dateTo = this.filterDateTo;      
				window.relevancePage.listenChoice = this.listenChoice;      
				window.relevancePage.typeChoice = this.typeChoice;
				if(this.filterProgNumber != undefined && this.filterProgNumber.toString() > 0){
					let arr = [];
					this.device.relevance.map((value)=>{
						if(value.id == this.filterProgNumber){
							arr.push(value);
						}
					})
					console.log(arr);
					return arr;
				}
				console.log("PAGINATE");
				return this.device.relevance.slice(start, end);*/
				return [];
			},
			pagesLength() {
				/*if(this.filterProgNumber != undefined && this.filterProgNumber.toString() > 0){
					return 1;
				}
				return Math.ceil(this.device.relevance.length / this.itemsPerPage)*/
				return 0;
			}
		},
		async created() {
			try {
				await axios.post(`${apiUrl}/device/noty/clean/relevance`);
                await this.$root.$children[0].chatNotyNumber();
                await this.$root.$children[0].relevanceNotyNumber();
			} catch (e) {
				console.log(e);
			}
			this.insertFilterFromLocal();
			SocketioService.setupSocketConnection(window.config.port_socket);

			SocketioService.subscribe('broadcast-id-value', this.handleBroadcastIdValue);

			SocketioService.subscribe('update-data-relevance', this.handleUpdateDataRelevance);

			await this.getNumberOfNotListenerRelevance();
			await this.getData("10-DEVICE");
			Relevance.dispatch(ADD_OBSERVER, () => {
				this.getData("11-DEVICE");
			});
		},
		beforeUnmount() {
			SocketioService.disconnect();
		},

		beforeDestroy() {
			SocketioService.unsubscribe('broadcast-id-value', this.handleBroadcastIdValue);
			SocketioService.unsubscribe('update-data-relevance', this.handleUpdateDataRelevance);
		},
	}
</script>

<style scoped lang="scss">
	.tooltip-info__container {
		width: 540px !important;
		background-color: #191623 !important;
		bottom: 60% !important;
		padding: 10px !important;
		border-left: 7px solid #a5a5c3;
		box-shadow: 8px 11px 19px 10px #000000;

		.info__text {
			text-align: left;

			div > p {
				margin-bottom: 7px;
			}
		}
	}

	.tooltip {
		position: relative;
		display: inline-block;
		border-bottom: 1px dotted black;
	}

	.tooltip .tooltiptext {
		visibility: hidden;
		width: 120px;
		background-color: #555;
		color: #fff;
		text-align: center;
		font-size: 13px;
		border-radius: 6px;
		padding: 5px 0;
		position: absolute;
		z-index: 1;
		bottom: -10% !important;
		left: 45%;
		margin-left: -60px;
		opacity: 0;
		transition: opacity 0.3s;
	}

	.simple-text {
		color: rgba(255, 255, 255, 0.7);
		font-size: 12px;
		margin-left: 12px;
	}

	.tooltip .tooltiptext::after {
		content: "";
		position: absolute;
		bottom: 100%;
		left: 50%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: transparent transparent #555 transparent;
	}

	.tooltip:hover .tooltiptext {
		visibility: visible;
		opacity: 1;
	}
</style>