<template>
    <v-list two-line>
        <v-list-item v-for="device in devices"
                     :key="device.id"
                     @click="beforeNavigate(device.id)">
            <div :style="{ color: chooseRitColor(deviceInfo(device)?.ritState) }"> &#9679; </div>

            <div v-if="device.os == 'Android'">
                <img src="../../../assets/icons/deviceOS/android-white.png" alt="Logo Dispositivo" />
            </div>
            <div v-else-if="device.os == 'iOS'">
                <img src="../../../assets/icons/deviceOS/apple-white.png" alt="Device Logo" />
            </div>
            <div v-else>
                <img src="../../../assets/icons/deviceOS/smartphone-white.png" alt="Device Logo" />
            </div>

            <v-list-item-content style="margin-left: 8px;">
                <v-list-item-title> <strong> {{ device.manufacturer }} {{ device.model }} </strong> </v-list-item-title>

                <v-list-item-subtitle>
                    <span v-if="deviceInfo(device)?.rgnr != null">
                        <strong>RGNR: </strong>{{ deviceInfo(device)?.rgnr }}
                        <br />
                    </span>
                    <span v-if="deviceInfo(device)?.ritCode != null">
                        <strong>RIT: </strong>{{ deviceInfo(device)?.ritCode }}
                    </span>
                    <span v-if="deviceInfo(device)?.ritAmbientCode != null">
                        - {{ deviceInfo(device)?.ritAmbientCode }}
                    </span>
                    <div><br /><v-divider inset></v-divider></div>
                </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-btn :to="`/device/dashboard`" icon>
                    <v-icon color="grey lighten-1">mdi-arrow-right-drop-circle</v-icon>
                </v-btn>
            </v-list-item-action>

        </v-list-item>
    </v-list>
</template>

<script>
    import Avatar from 'vue-avatar';
    import Device from "@/store/models/device.model";
    import { SET_DEVICE } from "@/store/mutations.type";
    import axios from '../../axios';
    import { apiUrl } from "@/common/endpoint.const";

    export default {
        name: "DeviceList",
        components: {
            Avatar
        },
        props: {
            devices: Array
        },
        data() {
            return {
                info: []
            }
        },
        methods: {
            beforeNavigate(deviceid) {
                sessionStorage.setItem("deviceId", deviceid);

                var device = Device.query().with('state').with('batteryRegister').with('trafficRegister').all();
                device = device.filter(d => d.id == deviceid)[0];
                Device.dispatch(SET_DEVICE, device);

                this.$router.push(`/device/dashboard`);
            },
            async fetchDeviceInfo() {
                var result = await axios.get(`${apiUrl}/device/get-device-info`)
                    .then((result) => {
                        console.log("GetDeviceInfo: ", result);
                        if (result.status == 200) this.info = result.data;
                    }).catch((error) => {
                        console.log(error);
                    });
            },
            deviceInfo(device) {
                return this.info.find(i => i.deviceId == device.id);
            },
            chooseRitColor(ritState) {
                switch (ritState) {
                    case 0:
                        return 'red';
                    case 1:
                        return 'orange';
                    case 2:
                        return 'yellow';
                    case 3:
                        return 'green';
                    default:
                        return 'grey';
                }
            }
        },
        async created() {
            this.fetchDeviceInfo();
        }
    }
</script>

<style scoped>
</style>