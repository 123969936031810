<template>
	<v-card class="mx-auto" :key="componentKey">
		<v-card-title>Console Comandi</v-card-title>
		<!--<v-divider></v-divider>-->
		<v-card-text>
			<span v-for="c in console.cmdHistory" :key="c.message">
				<div v-if="!c.params.includes('not-show-noty') && !c.params.includes('not-show-dashboard')">
					<span style="display: inline-block">{{ c.cmd }} {{ c.params.join(" ") }}</span>
					<v-spacer style="display: inline-block"></v-spacer>
					<v-icon style="display: inline-block" v-if="c.status === -1">mdi-cancel</v-icon>
					<v-icon style="display: inline-block" v-if="c.status === 0">mdi-clock</v-icon>
					<v-icon style="display: inline-block" v-if="c.status === 1">mdi-check</v-icon>
					<v-icon style="display: inline-block" v-if="c.status === 2">mdi-check-all</v-icon><br />
				</div>
			</span>
			<!-- Insert trash icon -->
			<v-btn color="red darken-1" @click="cleanHistory" dark class="mt-2" style="width:140px;">
				Pulisci
				<v-icon>mdi-trash-can</v-icon>
			</v-btn>
			<v-btn color="green darken-1" :disabled="disabledClean" @click="sendClean" dark class="mt-2" style="width:140px;">
				CLEAN
				<v-icon>mdi-download</v-icon>
			</v-btn>
			<form @submit.prevent="sendCmd">
				<v-text-field @keydown.up="historyNav('up')"
							  @keydown.down="historyNav('down')"
							  placeholder="comando"
							  v-model="cmd"
							  color="primary">
					<template #append>
						<v-btn icon @click.prevent="sendCmd">
							<v-icon>mdi-send</v-icon>
						</v-btn>
					</template>
				</v-text-field>
			</form>
		</v-card-text>
	</v-card>
</template>

<script>
	import Console from "@/store/models/console.model";
	import Vue from "vue";

	export default {
		name: "DeviceConsole",
		data() {
			return {
				cmd: "",
				cmdCursor: 0,
				componentKey: 0,
				disabledClean: false,
			};
		},
		props: ["device", "user"],
		methods: {
			async cleanHistory() {
				console.log("cleaning history...")
				console.log(this.console.cmdHistory);
				this.console.cmdHistory = [];
				await this.console.clearHistory();
				this.componentKey++;
			},
			async sendCmd() {
				let [cmd, ...params] = this.cmd.split(" ");
				// params = params.map(element => replaceAll(element, "**!!**", " "));
				console.log(this.console);
				const result = await this.console.sendCommand({ cmd, params, user: this.user });
				if (result) {
					this.cmd = "";
					this.cmdCursor = 0;
				}
			},
			historyNav(direction) {
				let cmd;
				if (direction === "down") {
					if (this.cmdCursor === 1) {
						this.cmd = "";
						--this.cmdCursor;
						return;
					} else if (this.cmdCursor === 0) {
						this.cmd = "";
						return;
					} else {
						cmd = this.console.cmdHistory[
							this.console.cmdHistory.length - --this.cmdCursor
						];
					}
				} else if (direction === "up") {
					if (this.cmdCursor === this.console.cmdHistory.length) {
						return;
					}
					cmd = this.console.cmdHistory[this.console.cmdHistory.length - ++this.cmdCursor];
				}
				this.cmd = `${cmd.cmd}` + (cmd.params.length > 0 ? ` ${cmd.params.join(" ")}` : "");
				console.log(
					this.console.cmdHistory,
					this.console.cmdHistory.length,
					this.cmdCursor
				);
			},
			async sendClean() {
				await this.console.sendCommand({ cmd: "CLEAN", params: [], user: this.user });
				this.disabledClean = true;
				setTimeout(() => {
					this.disabledClean = false;
				}, 60000);
			},
		},
		computed: {
			console() {
				return Console.query()
					.with("device")
					.with("cmdHistory")
					.where("deviceId", this.device.id)
					.first();
			},
		},
		async created() {
			if (!this.console) {
				console.log("creating console...");
				await Console.insertOrUpdate({
					data: {
						deviceId: this.device.id,
						device: this.device,
					},
				});
			}
		},
	};
</script>

<style scoped></style>
